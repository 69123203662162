// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import {Application} from "@hotwired/stimulus";

const application = Application.start();

application.debug = false;
window.Stimulus = application;

import ApplicationController from "./application_controller";
application.register("application", ApplicationController);

import Billing__FrequencySelector from "./billing/frequency_selector_controller";
application.register("billing--frequency-selector", Billing__FrequencySelector);

import Billing__ManageBllingButtonController from "./billing/manage_billing_button_controller";
application.register("billing--manage-billing-button", Billing__ManageBllingButtonController);

import BillingPlanSelector from "./billing/plan_selector_controller";
application.register("billing--plan-selector", BillingPlanSelector);

import AppearanceController from "./appearance_controller";
application.register("appearance", AppearanceController);

import AssignmentEditController from "./assignment/edit_controller";
application.register("assignment--edit", AssignmentEditController);

import BoardController from "./board_controller";
application.register("board", BoardController);

import ClipboardController from "./clipboard_controller";
application.register("clipboard", ClipboardController);

import CommandPaletteController from "./nav/command_palette_controller";
application.register("nav--command-palette", CommandPaletteController);

import ConfettiController from "./confetti_controller";
application.register("confetti", ConfettiController);

import Dropdown from "./dropdown_controller";
application.register("dropdown", Dropdown);

import DragController from "./drag_controller";
application.register("drag", DragController);

import DragOver from "./animation/drag_over_controller";
application.register("drag-over", DragOver);

import FlashController from "./flash_controller";
application.register("flash", FlashController);

import FlashInController from "./animation/flash_in_controller";
application.register("flash-in", FlashInController);

import FacebooKTrackingCodes from "./facebook_tracking_codes";
application.register("facebook-tracking-codes", FacebooKTrackingCodes);

import FormController from "./form_controller";
application.register("form", FormController);

import FormsComboboxController from "./forms/combobox_controller";
application.register("forms--combobox", FormsComboboxController);

import Forms__ClientListController from "./forms/client_list";
application.register("forms--client-list", Forms__ClientListController);

import FormsFileUploadController from "./forms/file_upload_controller";
application.register("forms--file-upload", FormsFileUploadController);

import FormsRichTextAreaController from "./forms/rich_text_area_controller";
application.register("forms--rich-text-area", FormsRichTextAreaController);

import Forms__ClientComboboxController from "./forms/client_combobox_controller";
application.register("forms--client-combobox", Forms__ClientComboboxController);

import HubSpotTrackingCode from "./hubspot_tracking_code";
application.register("hubspot-tracking-code", HubSpotTrackingCode);

import InfiniteScrollController from "./infinite_scroll_controller";
application.register("infinite-scroll", InfiniteScrollController);

import IntersectionController from "./intersection_controller";
application.register("intersection", IntersectionController);

import InvitationModalController from "./invitation_modal_controller";
application.register("invitation-modal", InvitationModalController);

import Ios__BadgesController from "./ios/badges_controller";
application.register("ios--badges", Ios__BadgesController);

import Ios__SignUpsController from "./ios/sign_ups_controller";
application.register("ios--sign-ups", Ios__SignUpsController);

import LessonsAutoPlayBannerController from "./lessons/auto_play_banner_controller";
application.register("lessons--auto-play-banner", LessonsAutoPlayBannerController);

import LessonsNextUpRollScrollController from "./lessons/next_up_roll_scroll_controller";
application.register("lessons--next-up-roll-scroll", LessonsNextUpRollScrollController);

import LessonsSourceUploadController from "./lessons/source_upload_controller";
application.register("lessons--source-upload", LessonsSourceUploadController);

import MenuController from "./menu_controller";
application.register("menu", MenuController);

import ModalController from "./modal_controller";
application.register("modal", ModalController);

import NavController from "./nav_controller";
application.register("nav", NavController);

import NewModalController from "./new_modal_controller";
application.register("new-modal", NewModalController);

import NotificationController from "./notification_controller";
application.register("notification", NotificationController);

import NotificationSectionController from "./inbox/notification_section_controller";
application.register("inbox--notification-section", NotificationSectionController);

import PlayerController from "./player_controller";
application.register("player", PlayerController);

import ReadSectionController from "./inbox/read_section_controller";
application.register("inbox--read-section", ReadSectionController);

import SearchController from "./search_controller";
application.register("search", SearchController);

import SearchAutoSubmitController from "./search/auto_submit_controller";
application.register("search--auto-submit", SearchAutoSubmitController);

import SelectableListController from "./selectable_list_controller";
application.register("selectable-list", SelectableListController);

import SelectNavigatorController from "./select_navigator_controller";
application.register("select-navigator", SelectNavigatorController);

import SettingsTeamController from "./settings/team_controller";
application.register("settings--team", SettingsTeamController);

import SignUps__SelectPlanController from "./sign_ups/select_plan_controller";
application.register("sign-ups--select-plan", SignUps__SelectPlanController);

import SlideOverController from "./slide_over_controller";
application.register("slide-over", SlideOverController);

import TabController from "./tab_controller";
application.register("tab", TabController);

import ToggleController from "./toggle_controller";
application.register("toggle", ToggleController);

import TooltipController from "./tooltip_controller";
application.register("tooltip", TooltipController);

import TrixContentController from "./trix_content_controller";
application.register("trix-content", TrixContentController);

import TurboFlashController from "./turbo_flash_controller";
application.register("turbo-flash", TurboFlashController);
